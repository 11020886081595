
import { PropType, defineComponent } from "vue";
// import Tags from "./Tags.vue";
// import MainPhotoAndRating from "./MainPhotoAndRating.vue";
import eventBus from "../EventBus";
import VotingVenue from "./VotingVenue.vue";
import { VoteType, Event } from "@/types";
import GoogleSpecial from "./GoogleSpecial.vue";
import { getDistanceFromLatLonInMiles } from "@/utilities/distanceCalcs";
import UserSubmittedSpecial from "./UserSubmittedSpecial.vue";
import {
  VoteableItem,
  VoteImplementingComponentName,
} from "../Session/RestaurantSession/-.types";
import { getVoteDetailsFromVoteableItem } from "./-component";

export default defineComponent({
  props: {
    items: Object as PropType<Array<VoteableItem>>,
    event: Object as PropType<Event>,
    type: String as PropType<VoteType>,
    props: Object,
    componentType: String as PropType<VoteImplementingComponentName>,
  },
  methods: {
    getDistanceFromLatLonInMiles,
    vote(s: VoteableItem) {
      eventBus.$emit("vote", getVoteDetailsFromVoteableItem(s));
    },
  },
  components: {
    GoogleSpecial,
    VotingVenue,
    UserSubmittedSpecial,
  },
});
