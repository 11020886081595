
import { PropType, defineComponent } from "vue";
import { googleUrl, instagramUrl } from "../../common/common";
import Special from "../Specials/Special.vue";
import { showVenueImagesModal } from "../Modals/ModalController";
import dayjs from "dayjs";
import Distance from "./Distance.vue";
import NonWeeklySpecial from "../Specials/NonWeeklySpecial.vue";
import { VoteableItem } from "../Session/RestaurantSession/-.types";
import { DayOfTheWeek } from "@/types";
// import CloudinaryImage from "../Cloudinary/CloudinaryImage.vue";

export default defineComponent({
  props: {
    venue: Object,
    lat: Number,
    long: Number,
    distance: Number,
    items: Object as PropType<Array<VoteableItem>>,
    voteableItem: Object as PropType<VoteableItem>,
    highlightDay: String as PropType<DayOfTheWeek>,
  },
  computed: {
    sVoteableItems() {
      return this.items.filter((i) => i.type === "w");
    },
    nVoteableItems() {
      return this.items.filter((i) => i.type === "n");
    },
  },
  methods: {
    instagramUrl,
    showVenueImagesModal,
    dayjs,
    googleUrl,
    lastVerified() {
      if (this.sVoteableItems.length === 0) {
        return dayjs(new Date(this.voteableItem.parent.lastVerified)).format(
          "M/D/YY"
        );
      } else {
        // return the oldest lastVerified date in sVoteableItems (the weekly specials)
        return dayjs(
          new Date(
            Math.min(
              ...this.sVoteableItems.map((s) =>
                new Date(s.additionalData.lastVerified).getTime()
              )
            )
          )
        ).format("M/D/YY");
      }
    },
  },
  components: { Special, Distance, NonWeeklySpecial },
});
