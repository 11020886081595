import { getRestaurantsAndVotes, getVoteIdentifier } from "@/common/common";
import { ChatMessage } from "@/components/Chat/-types";
import { showChatModal } from "@/components/Modals/ModalController";
import EventService from "@/services/EventService";
import SessionService from "@/services/SessionService";
import store from "@/store/store";
import {
  Event,
  GetVotesResponse,
  RestaurantDataWithVotes,
  UserInputVotingOptionsWithVotes,
  VenueWithVotingSpecial,
} from "@/types";
import dayjs, { Dayjs } from "dayjs";
import { VoteableItem } from "./-.types";
import { TYPE, useToast } from "vue-toastification";
import VotingService from "@/services/VotingService";

export default class RestaurantSessionController {
  // Properties to represent the state of the restaurant session
  event: Event;
  private initialLoadFinished: boolean;
  private shouldShowVotingRulesModalButton: boolean;
  private _voteableItems: VoteableItem[];
  private _votes: GetVotesResponse[];
  private _now: Dayjs;
  private _messages: ChatMessage[];
  private _triedRefreshing: boolean;

  private constructor() {
    // Private to prevent direct instantiation with 'new'
  }

  // Static method for initialization
  static async createInstance(
    eventId: string
  ): Promise<RestaurantSessionController> {
    const controller = new RestaurantSessionController();
    await controller.initialize(eventId);
    return controller;
  }

  private async initialize(id: string): Promise<void> {
    try {
      this._now = dayjs();
      await this.loadAsyncData(id);
    } catch (error) {
      console.error("Failed to initialize RestaurantSessionController:", error);
      // Handle initialization failure (e.g., by setting default values or retrying)
    }
  }

  private async loadAsyncData(id: string): Promise<void> {
    store.commit("setLoading", true);
    this.initialLoadFinished = false;
    try {
      const eventDataResponse = await EventService.getEventDetails(id);
      this.event = eventDataResponse.data.event;
      const { restaurants, venues, userInputVotingOptions } =
        await getRestaurantsAndVotes(id);
      this.initializeVoteableItems(restaurants, venues, userInputVotingOptions);
      await this.getVotes();
      const chatMessageResponse = await SessionService.getChatMessages(
        this.event.eventId
      );
      this._messages = chatMessageResponse.data.messages;
      this.initialLoadFinished = true;
    } catch (e) {
      console.log(e);
      this.initialLoadFinished = true;
      this.event = null;
    } finally {
      store.commit("setLoading", false);
    }
  }

  initializeVoteableItems(
    restaurants: RestaurantDataWithVotes[],
    venues: VenueWithVotingSpecial[],
    userInputVotingOptions: UserInputVotingOptionsWithVotes[]
  ): void {
    this._voteableItems = [];
    const ids = new Set<string>();
    for (const r of restaurants) {
      const key = getVoteIdentifier("g", r.placeId);
      this._voteableItems.push({
        id: r.placeId,
        name: r.name,
        type: "g",
        votingKey: key,
        shouldRender: true,
        votes: this._votes,
        voteCount: 0,
        aliasesWhoVotedForThis: [],
        parent: null,
        additionalData: r,
      });
    }
    for (const v of venues) {
      for (const s of v.Specials) {
        const idString = v.id.toString();
        const sr = !ids.has(idString);
        ids.add(idString);
        const key = getVoteIdentifier("w", s.id);
        this._voteableItems.push({
          id: s.id,
          name: (s.name ?? "") + " at " + (v.name ?? ""),
          type: "w",
          votingKey: key,
          votes: this._votes,
          shouldRender: sr,
          voteCount: 0,
          aliasesWhoVotedForThis: [],
          parent: v,
          additionalData: s,
        });
      }
      for (const n of v.NonWeeklySpecials) {
        const idString = v.id.toString();
        const sr = !ids.has(idString);
        ids.add(idString);
        const key = getVoteIdentifier("n", n.id);
        this._voteableItems.push({
          id: n.id,
          name: (n.name ?? "") + " at " + (v.name ?? ""),
          type: "n",
          votingKey: key,
          votes: this._votes,
          shouldRender: sr,
          voteCount: 0,
          aliasesWhoVotedForThis: [],
          parent: v,
          additionalData: n,
        });
      }
    }
    for (const u of userInputVotingOptions) {
      const key = getVoteIdentifier("u", u.id);
      this._voteableItems.push({
        id: u.id,
        name: u.name,
        type: "u",
        votingKey: key,
        votes: this._votes,
        shouldRender: true,
        voteCount: 0,
        aliasesWhoVotedForThis: [],
        parent: null,
        additionalData: u,
      });
    }
  }

  get messages(): ChatMessage[] {
    return this._messages;
  }

  voteCount(k: string): number {
    return (
      this._votes?.filter(
        (vote) => getVoteIdentifier(vote.type, vote.choice) === k
      ).length || 0
    );
  }

  getAliasesWhoVotedForThis(k: string): string[] {
    return this._votes
      ?.filter((vote) => getVoteIdentifier(vote.type, vote.choice) === k)
      .map((vote) => vote.userAlias);
  }

  sortVoteableItems(): void {
    (this._voteableItems as Array<VoteableItem>).sort(
      (a: VoteableItem, b: VoteableItem) => {
        if (this.event.winningRestaurant) {
          if (a.name === this.event.winningRestaurantDetails.name) {
            return -1;
          } else if (b.name === this.event.winningRestaurantDetails.name) {
            return 1;
          }
        }
        let aCount = a.voteCount;
        let bCount = b.voteCount;
        if (a.type === "n" || a.type === "w") {
          const aSiblings = this._voteableItems.filter(
            (v) => v.parent?.id && v.parent.id === a.parent.id
          );
          aCount = Math.max(...aSiblings.map((v) => v.voteCount));
        }
        if (b.type === "n" || b.type === "w") {
          const bSiblings = this._voteableItems.filter(
            (v) => v.parent?.id && v.parent.id === b.parent.id
          );
          bCount = Math.max(...bSiblings.map((v) => v.voteCount));
        }
        if (aCount > bCount) {
          return -1;
        } else if (aCount < bCount) {
          return 1;
        }
        // If votes are equal, prioritize elements with a type of "non-weekly-special" (n)
        if (a.type === "n" && b.type !== "n") {
          return -1;
        } else if (a.type !== "n" && b.type === "n") {
          return 1;
        }
        // If votes are equal and neither is "non-weekly-special", prioritize "weekly-special"
        if (a.type === "w" && b.type !== "w") {
          return -1;
        } else if (a.type !== "w" && b.type === "w") {
          return 1;
        }

        // If votes are equal and neither is "weekly-special" or "non-weekly-special", prioritize "user-input"
        if (a.type === "u" && b.type !== "u") {
          return -1;
        } else if (a.type !== "u" && b.type === "u") {
          return 1;
        }
        // If both have the same type, return 0 (no change in order)
        return 0;
      }
    );
  }

  addMessage(message: ChatMessage): void {
    this._messages.push(message);
  }

  get voteableItems(): Array<VoteableItem> {
    return this._voteableItems;
  }

  set voteableItems(value: Array<VoteableItem>) {
    this._voteableItems = value;
  }

  get now(): Dayjs {
    return this._now;
  }

  set now(value: Dayjs) {
    this._now = value;
  }

  getHeaderText(): string {
    if (this.initialLoadFinished && this.event === null) {
      return "Error loading event";
    } else if (this.event.currentStatus === "end-restaurant-voting-early") {
      return "Creator ended vote early. Votes are being tallied...";
    } else if (this.event?.cancelled) {
      return `EVENT WAS CANCELED ${
        this.event.cancelledBy === "SYSTEM" ? " - No votes" : " by creator"
      }`;
    } else {
      return this.event?.winningRestaurant ? "Poll has ended." : "Voting Time!";
    }
  }

  getHeaderClass(): string {
    if (this.event?.cancelled) {
      return "text-danger text-center display-5 fw-bold";
    } else {
      return "text-dark-blue display-5 fw-bold";
    }
  }

  getFormattedEventDate(): string {
    return this.event.eventStartDateTime
      ? dayjs(this.event.eventStartDateTime).format("ddd MMM D, YYYY")
      : "";
  }

  getFormattedEventTime(): string {
    return this.event.eventStartDateTime
      ? dayjs(this.event.eventStartDateTime).format("LT")
      : "";
  }

  getFormattedTimeUntilVoteEnds(
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    rvedt: Dayjs
  ): string {
    const formattedDate = rvedt.format("llll");
    const dayString = days > 0 ? days + "d" : "";
    const hourString = hours > 0 || days > 0 ? hours + "h" : "";
    const minuteString = minutes > 0 && days === 0 ? minutes + "m" : "";
    const secondsString = hours === 0 && days === 0 ? seconds + "s" : "";
    // add these together to get one space between each e.g. "1d 2h 3m 4s"
    const timeStrings = [dayString, hourString, minuteString, secondsString];
    const formattedTime = timeStrings.filter((str) => str !== "").join(" ");
    return `ends on ${formattedDate} (${formattedTime})`;
  }

  getHeaderSubtext(): string {
    if (this.event.winningRestaurant) {
      if (this.event.winningRestaurant === "No votes") {
        return "No votes were cast. Event was cancelled.";
      } else {
        return "The winner is " + this.event.winningRestaurant + "!";
      }
    } else if (this.event.cancelled) {
      return "";
    }
    return `Poll ${this.timeUntilVoteEnds()}`;
  }

  timeUntilVoteEnds(): string {
    const rvedt = dayjs(this.event.restaurantVoteEndDateTime);
    const cs = this.event.currentStatus;
    if (this._now.isBefore(rvedt)) {
      if (cs === "restaurant-voting") {
        const diff = dayjs.duration(rvedt.diff(this._now));
        const days = Math.floor(diff.asDays());
        const hours = diff.hours();
        const minutes = diff.minutes();
        const seconds = diff.seconds();
        return this.getFormattedTimeUntilVoteEnds(
          days,
          hours,
          minutes,
          seconds,
          rvedt
        );
      } else if (cs === "end-restaurant-voting-early") {
        ("ends soon!");
      }
    } else {
      if (
        !this._triedRefreshing &&
        this._now.isAfter(rvedt) &&
        !this.event.winningRestaurant &&
        this.event.currentStatus === "restaurant-voting"
      ) {
        this.refreshData(this.event.eventId.toString());
        this._triedRefreshing = true;
      }
      return "ended " + this._now.to(rvedt);
    }
    return "";
  }

  getHeaderSubtextClass(): string {
    return "text-info fw-bold";
  }

  nooneVoted(): boolean {
    return this.event?.winningRestaurant === "No votes";
  }

  async someoneVoted(voteData): Promise<void> {
    await this.getVotes();
    if (voteData.userFirebaseUid != store.state.userFirebaseUid) {
      const toast = useToast();
      const msg = voteData.added
        ? "✅ " +
          voteData.userAlias.toLowerCase() +
          " voted for " +
          voteData.vote
        : "❌ " +
          voteData.userAlias.toLowerCase() +
          " removed a vote for " +
          voteData.vote +
          ".";
      toast(msg, {
        timeout: 2050,
        type: TYPE.DEFAULT,
        icon: false,
      });
    }
  }

  userJoinedChat(data): void {
    if (data.userAlias.toLowerCase() !== store.state.userAlias.toLowerCase()) {
      const toast = useToast();
      toast("👋 " + data.userAlias.toLowerCase() + " has joined the chat", {
        timeout: 2050,
        type: TYPE.DEFAULT,
        icon: false,
      });
    }
  }

  userLeftChat(data): void {
    // TODO this is a bug and should be fixed when connection and reestablishment issues are ironed out to prevent spam
    if (data.userAlias.toLowerCase() == store.state.userAlias.toLowerCase()) {
      const toast = useToast();
      toast(data.userAlias.toLowerCase() + " has left the chat", {
        timeout: 2050,
        type: TYPE.DEFAULT,
        icon: false,
      });
    }
  }

  async getVotes(): Promise<void> {
    try {
      this._votes = [];
      const response = await VotingService.getVotes(
        this.event.eventId,
        "restaurant",
        store.state.userFirebaseUid
      );
      this._votes = response.data.votes;
      this.updateVotesOnVoteableItems();
      this.sortVoteableItems();
    } catch (e) {
      console.log(e);
    }
  }

  updateVotesOnVoteableItems(): void {
    for (const v of this._voteableItems) {
      v.voteCount = this.voteCount(v.votingKey);
      v.aliasesWhoVotedForThis = this.getAliasesWhoVotedForThis(v.votingKey);
    }
    this.sortVoteableItems();
  }

  getVoteLeader(): string {
    if (this._votes === undefined) {
      return "";
    } else if (this.event?.cancelled || this.event.winningRestaurant) {
      return "";
    } else if (this._votes.length === 0) {
      if (this.initialLoadFinished && !store.state.loading) {
        return "No votes yet";
      } else {
        return "";
      }
    } else {
      // Step 1: Create a mapping from each vote.choice + vote.type to the vote object
      const voteMapping = this._votes.reduce((acc, vote) => {
        const key = vote.choice.toString() + vote.type;
        acc[key] = vote; // Store the entire vote object
        return acc;
      }, {});

      // Create an object mapping each vote.choice + vote.type to its count
      const counts = this._votes.reduce((acc, vote) => {
        const key = vote.choice.toString() + vote.type;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      // Convert this object into an array of [choice + type, count] pairs
      const entries = Object.entries(counts);

      // Use reduce to find the pair with the highest count
      const highestCountEntry = entries.reduce((acc, entry) =>
        entry[1] > acc[1] ? entry : acc
      );

      // Find all entries with the highest count
      const highestCountEntries = entries.filter(
        (entry) => entry[1] === highestCountEntry[1]
      );

      // If there is a tie
      if (highestCountEntries.length > 1 && highestCountEntries.length < 3) {
        return (
          "Vote is tied between " +
          highestCountEntries
            .map((entry) => voteMapping[entry[0]].name)
            .join(" and ")
        );
      } else if (highestCountEntries.length >= 3) {
        // If there are more than 3 votes, return the first 3
        return (
          "Vote is tied between " +
          highestCountEntries
            .slice(0, 2)
            .map((entry) => voteMapping[entry[0]].name)
            .join(", ") +
          ", and more..."
        );
      } else {
        // Return the vote with the leaderChoice
        return "Current leader: " + voteMapping[highestCountEntry[0]].name;
      }
    }
  }

  showVoteItems(): boolean {
    return this.initialLoadFinished;
  }

  showSessionChatModal(): void {
    store.commit("setLoading", true);
    showChatModal(this.event.eventId.toString(), this._messages);
    store.commit("setLoading", false);
  }

  async refreshData(id: string): Promise<void> {
    await this.loadAsyncData(id);
  }
}
