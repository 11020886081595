
import MoneySigns from "./MoneySigns.vue";
import Distance from "./Distance.vue";
import Rating from "./Rating.vue";
import { PropType, defineComponent } from "vue";
import VotingButton from "./VotingButton.vue";
import { GetVotesResponse, VoteType } from "@/types";
import { VoteableItem } from "../Session/RestaurantSession/-.types";
export default defineComponent({
  props: {
    vicinity: String,
    userRatingsTotal: Number,
    moneySigns: Number,
    tags: Array,
    distance: Number,
    title: String,
    rating: Number,
    type: String as PropType<VoteType>,
    placeId: String,
    numberOfVotes: Number,
    aliasesWhoVotedForThis: Array<string>,
    voteableItem: Object as PropType<VoteableItem>,
  },
  components: { Rating, Distance, MoneySigns, VotingButton },
  computed: {
    googleUrl() {
      return (
        "https://www.google.com/maps/search/?api=1&query=" +
        this.vicinity +
        "&query_place_id=" +
        this.placeId
      );
    },
  },
});
