import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moneySigns, (index) => {
      return (_openBlock(), _createElementBlock("i", {
        key: index,
        class: "bi bi-currency-dollar opacity-50"
      }))
    }), 128))
  ]))
}