
import RestaurantSessionHeader from "./RestaurantSessionHeader.vue";
import { Event, VoteDetails, VotePostingDetails } from "../../../types";
import { defineComponent, nextTick } from "vue";
import VotingService from "../../../services/VotingService";
import {
  showGuestCannotVoteModal,
  showVotingRulesModal,
} from "../../Modals/ModalController";
import { TYPE, useToast } from "vue-toastification";
import { ChatMessage } from "@/components/Chat/-types";
import eventBus from "../../EventBus";
import VotingContainer from "@/components/Voting/VotingContainer.vue";
import dayjs, { Dayjs } from "dayjs";
import refreshMixin from "@/mixins/refreshMixin";
import RestaurantSessionController from "./RestaurantSessionController";
import { App } from "@capacitor/app";

export default defineComponent({
  mixins: [refreshMixin],
  beforeRouteLeave() {
    this.$socketio.emit("leave_chat", {
      userFirebaseUid: this.$store.state.userFirebaseUid,
      eventId: this.eventId,
    });
    this.$socketio.off();
    if (this.appStateChangeListenerHandle) {
      this.appStateChangeListenerHandle.remove();
    }
    return true;
  },
  data() {
    const eventId = this.$route.params.eventId as unknown as number | string;
    return {
      rsc: null as RestaurantSessionController | null,
      reconnectorInterval: null,
      messages: [] as ChatMessage[],
      eventId,
      appStateChangeListenerHandle: null as string,
      event: {} as Event,
      errorMessage: "" as string,
    };
  },
  async created() {
    await this.$store.dispatch("setLoading", true);
    await nextTick();
    this.rsc = await RestaurantSessionController.createInstance(this.eventId);
    this.startCountdown();
  },
  async mounted() {
    try {
      await this.addActiveListenerForApps();
      this.setupSocketListeners();
      eventBus.$on("vote", async (v: VoteDetails) => {
        await this.castVote(v);
      });
      eventBus.$on("modal-closed", this.reestablishSocketConnections());
      this.$socketio.on("connect", this.setupSocketListeners);
      if (!this.$store.state.seenVotingRules) {
        showVotingRulesModal();
      }
      this.startSocketReconnector();
    } catch (e) {
      console.log(e);
    }
  },
  beforeUnmount() {
    this.$socketio.off("voted", this.handleVoted);
    this.$socketio.off("connect", this.setupSocketListeners);
    try {
      // wrap this in a try catch because it may or may not be attached.
      eventBus.$off("modal-closed", this.reestablishSocketConnections);
    } catch (e) {
      return;
    }
    eventBus.$off("vote");
    if (this.reconnectorInterval) {
      clearInterval(this.reconnectorInterval);
    }
  },
  methods: {
    showVotingRulesModal,
    async addActiveListenerForApps() {
      this.appStateChangeListenerHandle = await App.addListener(
        "appStateChange",
        async ({ isActive }) => {
          //   const toast = useToast();
          //   const state = isActive ? "ACTIVE" : "NOT ACTIVE";
          //   const socketState = this.$socketio.connected
          //     ? "CONNECTED"
          //     : "DISCONNECTED";
          //   toast("App state -> " + state + ", socket state is " + socketState, {
          //     timeout: false,
          //     type: TYPE.INFO,
          //   });
          if (isActive && !this.$socketio.connected) {
            // const toast = useToast();
            // toast("Socket not connected, reconnecting", {
            //   timeout: 7500,
            //   type: TYPE.INFO,
            // });
            this.$socketio.connect();
            await this.refresh();
            // toast("good reconnection", {
            //   timeout: false,
            //   type: TYPE.SUCCESS,
            // });
          } else if (isActive) {
            await this.refresh();
            // toast("good reestablishment", {
            //   timeout: false,
            //   type: TYPE.SUCCESS,
            // });
          }
        }
      );
    },
    getFormattedTimeUntilVoteEnds(
      days: number,
      hours: number,
      minutes: number,
      seconds: number,
      rvedt: Dayjs
    ) {
      const formattedDate = rvedt.format("llll");
      const dayString = days > 0 ? days + "d" : "";
      const hourString = hours > 0 || days > 0 ? hours + "h" : "";
      const minuteString = minutes > 0 && days === 0 ? minutes + "m" : "";
      const secondsString = hours === 0 && days === 0 ? seconds + "s" : "";
      // add these together to get one space between each e.g. "1d 2h 3m 4s"
      const timeStrings = [dayString, hourString, minuteString, secondsString];
      const formattedTime = timeStrings.filter((str) => str !== "").join(" ");
      return `ends on ${formattedDate} (${formattedTime})`;
    },
    reestablishSocketConnections() {
      this.$socketio.emit("leave_chat", {
        userFirebaseUid: this.$store.state.userFirebaseUid,
        eventId: this.eventId,
      });
      this.setupSocketListeners();
    },
    async refresh() {
      await this.$store.commit("setLoading", true);
      await nextTick();
      await this.rsc.refreshData(this.eventId);
      this.reestablishSocketConnections();
      this.completeRefresh();
      this.$store.commit("setLoading", false);
    },
    startCountdown() {
      setInterval(() => {
        this.rsc.now = dayjs();
      }, 1000);
    },
    startSocketReconnector() {
      // Check every 5 seconds and reinitializes the listeners if needed
      this.reconnectorInterval = setInterval(async () => {
        if (
          !this.$socketio.connected &&
          !this.$store.state.loading &&
          navigator.onLine
        ) {
          this.$socketio.connect();
          //   const toast = useToast();
          //   toast("Reconnector activated", {
          //     timeout: 7500,
          //     type: TYPE.INFO,
          //   });
        }
      }, 5000);
    },
    async castVote(e: VotePostingDetails) {
      try {
        if (this.$store.state.signedInAsGuest) {
          showGuestCannotVoteModal();
        } else {
          const { name, choice, type } = e;
          const response = await VotingService.castVote(
            this.eventId,
            "restaurant", // only one type at this point
            type,
            this.$store.state.userFirebaseUid,
            choice,
            name
          );

          if (response.data.problem) {
            this.voteFailed(response.data.msg);
          } else {
            this.$socketio.emit("voted", {
              userFirebaseUid: this.$store.state.userFirebaseUid,
              eventId: this.eventId,
              vote: name,
              userAlias:
                this.$store.state.userAlias?.toLowerCase() ?? "someone",
              added: response.data.added,
              meta: {
                type: type,
                choice: choice,
              },
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    setupSocketListeners() {
      if (!this.$socketio.connected) {
        // const toast = useToast();
        // toast("Socket not connected, reconnecting", {
        //   timeout: 7500,
        //   type: TYPE.INFO,
        // });
        this.$socketio.connect();
      }
      this.joinChat();
      this.$socketio.off("chat_message");
      this.$socketio.off("user_joined_chat");
      this.$socketio.off("user_left_chat");
      this.$socketio.off("voted");
      this.$socketio.on("chat_message", (message) => {
        this.rsc?.addMessage(message);
      });
      this.$socketio.on("user_joined_chat", (data) => {
        this.rsc?.userJoinedChat(data);
      });
      this.$socketio.on("user_left_chat", (data) => {
        this.rsc?.userLeftChat(data);
      });
      this.$socketio.on("voted", this.handleVoted);
    },
    async handleVoted(data) {
      await this.rsc?.someoneVoted(data);
    },
    joinChat(): void {
      try {
        this.$socketio.emit("join_chat", {
          userFirebaseUid: this.$store.state.userFirebaseUid,
          userAlias: this.$store.state.userAlias.toLowerCase(),
          eventId: this.eventId,
        });
      } catch (e) {
        console.log(e);
      }
    },
    voteFailed(m: string) {
      this.errorMessage = m;
      const toast = useToast();
      toast(this.errorMessage, { type: TYPE.ERROR, timeout: 7500 });
    },
  },
  components: { VotingContainer, RestaurantSessionHeader },
});
