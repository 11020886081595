import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SessionVotingItem = _resolveComponent("SessionVotingItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_TransitionGroup, {
          name: "voteables",
          tag: "div"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voteableItems, (d) => {
              return (_openBlock(), _createBlock(_component_SessionVotingItem, {
                key: d.votingKey,
                props: _ctx.getComponentProps(d.type, d),
                componentType: _ctx.getComponentType(d.type),
                type: d.type,
                event: _ctx.event
              }, null, 8, ["props", "componentType", "type", "event"]))
            }), 128))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}