
import Distance from "./Distance.vue";
import { PropType, defineComponent } from "vue";
import VotingButton from "./VotingButton.vue";
import { GetVotesResponse, Vote, VoteType } from "@/types";
import { getQualifiedWebsiteName } from "@/common/common";
import { VoteableItem } from "../Session/RestaurantSession/-.types";
export default defineComponent({
  props: {
    address: String,
    distance: Number,
    name: String,
    id: Number,
    type: String as PropType<VoteType>,
    placeId: String,
    website: String,
    description: String,
    creator: String,
    numberOfVotes: Number,
    aliasesWhoVotedForThis: Array<string>,
    voteableItem: Object as PropType<VoteableItem>,
  },
  components: { Distance, VotingButton },
  computed: {
    qualifiedWebsiteName() {
      // Check if the website starts with 'https://' and 'www.', if not, add them
      return getQualifiedWebsiteName(this.website);
    },
  },
});
