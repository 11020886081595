
import { Event, VoteType } from "@/types";
import SessionVotingItem from "./SessionVotingItem.vue";
import { defineComponent, PropType } from "vue";
import {
  VoteableItem,
  VoteImplementingComponentName,
} from "../Session/RestaurantSession/-.types";
import { getDistanceFromLatLonInMiles } from "@/utilities/distanceCalcs";
import dayjs from "dayjs";
import { weekdays } from "@/common/common";
export default defineComponent({
  components: { SessionVotingItem },
  props: {
    voteableItems: Array as PropType<Array<VoteableItem>>,
    event: Object as PropType<Event>,
  },
  methods: {
    getComponentProps(v: VoteType, item: VoteableItem) {
      if (v === "n" || v === "w") {
        return {
          venue: item.parent,
          lat: item.parent.lat,
          long: item.parent.long,
          items: this.voteableItems.filter(
            (i) => i.parent?.id && i.parent.id === item.parent.id
          ),
          distance: this.distance(item),
          votes: item.votes,
          numberOfVotes: item.voteCount,
          aliasesWhoVotedForThis: item.aliasesWhoVotedForThis,
          voteableItem: item,
          highlightDay:
            v === "w"
              ? weekdays[
                  dayjs(this.event.eventStartDateTime).day()
                ].toLowerCase()
              : null,
        };
      } else if (v === "g") {
        return {
          vicinity: item.additionalData.vicinity,
          userRatingsTotal: item.additionalData.userRatingsTotal,
          title: item.name,
          moneySigns: item.additionalData.priceLevel,
          tags: item.additionalData.tags,
          distance: this.distance(item),
          rating: item.additionalData.rating,
          type: item.type,
          votes: item.votes,
          placeId: item.id,
          numberOfVotes: item.voteCount,
          aliasesWhoVotedForThis: item.aliasesWhoVotedForThis,
          voteableItem: item,
        };
      } else if (v === "u") {
        return {
          id: item.id,
          votes: item.votes,
          address: item.additionalData.address,
          type: "u",
          distance: this.distance(item),
          name: item.name,
          website: item.additionalData.website,
          description: item.additionalData.description,
          creator: item.additionalData.creator,
          numberOfVotes: item.voteCount,
          aliasesWhoVotedForThis: item.aliasesWhoVotedForThis,
          voteableItem: item,
        };
      } else {
        throw new Error("Invalid special type");
      }
    },
    getComponentType(v: VoteType): VoteImplementingComponentName {
      if (v === "g") {
        return "GoogleSpecial";
      } else if (v === "n" || v === "w") {
        return "VotingVenue";
      } else if (v === "u") {
        return "UserSubmittedSpecial";
      } else {
        throw new Error("Invalid special type");
      }
    },
    distance(item: VoteableItem) {
      if (item.type === "u") {
        return getDistanceFromLatLonInMiles(
          this.event.sessionLat,
          this.event.sessionLong,
          item.additionalData.lat,
          item.additionalData.long
        );
      }
      return getDistanceFromLatLonInMiles(
        this.event.sessionLat,
        this.event.sessionLong,
        item.additionalData.latitude ?? item.parent.lat,
        item.additionalData.longitude ?? item.parent.long
      );
    },
  },
});
