import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center" }
const _hoisted_2 = { class: "text-dark-blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("small", null, "Event Time: " + _toDisplayString(_ctx.rsc?.getFormattedEventDate()) + " at " + _toDisplayString(_ctx.rsc?.getFormattedEventTime()), 1),
    _createElementVNode("small", {
      class: _normalizeClass(_ctx.rsc?.getHeaderClass())
    }, _toDisplayString(_ctx.rsc?.getHeaderText()), 3),
    _createElementVNode("small", {
      class: _normalizeClass(_ctx.rsc?.getHeaderSubtextClass())
    }, _toDisplayString(_ctx.rsc?.getHeaderSubtext()), 3),
    _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.rsc?.getVoteLeader()), 1)
  ]))
}