
import { defineComponent, PropType } from "vue";
import RestaurantSessionController from "./RestaurantSessionController";
export default defineComponent({
  props: {
    rsc: {
      type: Object as PropType<RestaurantSessionController>,
      required: true,
    },
  },
});
