import { VotePostingDetails, VoteType } from "@/types";
import { VoteableItem } from "../Session/RestaurantSession/-.types";

export function getVoteDetailsFromVoteableItem(
  v: VoteableItem
): VotePostingDetails {
  const split = v.votingKey.split("|");
  assertIsVoteType(split[0]);
  const type: VoteType = split[0] as VoteType;
  const choice = split[1];
  if (!choice) throw new Error("Invalid voting key");
  return {
    type: type,
    choice: choice,
    name: v.name,
  };
}

function assertIsVoteType(value: string): asserts value is VoteType {
  if (value !== "u" && value !== "g" && value !== "w" && value !== "n") {
    throw new Error(`Invalid VoteType: ${value}`);
  }
}
